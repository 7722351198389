// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.text {
	&--center {
		text-align: center;
	}
}

@media print {
  	.print {
  		&--hidden {
  			display: none;
  		}
  	}

  	.invoice {
  		border: 3px solid black;
  		padding: 30px;
  		text-align: center;
  		font-size: 2em;

  		h2 {
  			font-size: 4em;
  		}
  	}
}